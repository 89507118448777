import React from 'react'
import Grid from '@material-ui/core/Grid'
import logo from '../../images/logo.svg'
import whiteLogo from '../../images/white_logo.svg'
import './index.scss'
const now = new Date()
now.getFullYear()
export default ({ dark }) => (
  <div className={dark ? 'dark-footer' : ''}>
    <Grid container className="footer section-content">
      <Grid item xs={12} sm={3} md={2}>
        <img
          src={dark ? whiteLogo : logo}
          className="logo"
          alt="Upload My Stock Logo"
        />
        <div className="address">
          Los Angeles,
          <br /> California USA
        </div>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <div className="col-title">PAGES</div>
        <a className="links" href="/blog/">
          Stock Blog
        </a>
        <a className="links" href="/faq">
          FAQ
        </a>
        <a className="links" href="/faq">
          Contact
        </a>
        {/* <a className="links" href="/app/workbench">
          Work Bench
        </a> */}
      </Grid>

      <Grid item xs={12} sm={3} md={2}>
        <div className="col-title">TERMS</div>
        <a className="links" href="/terms">
          Terms of Service
        </a>
        <a className="links" href="/privacy-policy">
          Privacy Policy
        </a>
      </Grid>

      <Grid item xs={12} sm={3} md={2}>
        <div className="col-title">ATTRIBUTION</div>
        <a className="links" href="http://www.freepik.com">Freepik</a>
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <div className="col-title">SECURITY</div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.mcafeesecure.com/verify?host=uploadmystock.com"
        >
          <img
            className="mfes-trustmark"
            border="0"
            src="//cdn.ywxi.net/meter/uploadmystock.com/102.svg"
            width="120"
            height="50"
            title="McAfee SECURE sites help keep you safe from identity theft, credit card fraud, spyware, spam, viruses and online scams"
            alt="McAfee SECURE sites help keep you safe from identity theft, credit card fraud, spyware, spam, viruses and online scams"
            onContextMenu={() => {
              window.open(
                'https://www.mcafeesecure.com/verify?host=uploadmystock.com'
              )
              return false
            }}
          />
        </a>
      </Grid>
    </Grid>
    <div className="right-reserved">
      Copyright © {now.getFullYear()}. All Rights Reserved
    </div>
  </div>
)
