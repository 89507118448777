import React from 'react'
import { Link, graphql } from 'gatsby'
import RightIcon from '@material-ui/icons/KeyboardArrowRight'
import { Typography, Grid, Divider } from '@material-ui/core'
import Footer from '../../components/Footer'
import BlogAppBar from '../../components/BlogAppBar'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import './index.scss'

export default class Blog extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    const allPosts = []
    const topArticles = []
    const featured = []

    posts.forEach(post => {
      if (post.node.frontmatter.topArticle) {
        topArticles.push(post)
      } else if (post.node.frontmatter.featured) {
        featured.push(post)
      } else {
        allPosts.push(post)
      }
    })
    allPosts.concat(topArticles.slice(4, topArticles.length - 1))

    return (
      <div className="blog-posts">
        <Helmet title={`Upload My Stock - Blog`}>
          <meta
            name="Description"
            content="Tips, guides and inspiration to become a top selling stock photographer."
          />
        </Helmet>
        <BlogAppBar />
        <div className="blog-container">
          {featured.map(({ node: post }) => {
            return (
              <Link
                className="blog-link"
                to={post.frontmatter.path}
                key={post.id}
              >
                <Grid container className="featured" spacing={32}>
                  <Grid item xs={12} sm={8}>
                    <Img
                      sizes={
                        post.frontmatter.featuredImage.childImageSharp.sizes
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} className="text">
                    <div>
                      <Typography variant="overline" className="post-date">
                        {post.frontmatter.date}
                      </Typography>

                      <Typography variant="h2" gutterBottom>
                        {post.frontmatter.title}
                      </Typography>

                      <Typography variant="subtitle1" gutterBottom>
                        {post.frontmatter.subtitle}
                      </Typography>

                      <Typography variant="button" className="fake-read-button">
                        Read More <RightIcon />
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Link>
            )
          })}

          {topArticles.length > 0 && (
            <Typography variant="h2" gutterBottom>
              Top Articles
            </Typography>
          )}

          <Grid container spacing={32}>
            {topArticles.slice(0, 3).map(({ node: post }) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="recent-posts"
                  key={post.id}
                >
                  <Link className="blog-link" to={post.frontmatter.path}>
                    <Typography variant="overline">
                      {post.frontmatter.date}
                    </Typography>
                    <Typography variant="h4">
                      {post.frontmatter.title}
                    </Typography>
                    <Img
                      sizes={
                        post.frontmatter.featuredImage.childImageSharp.sizes
                      }
                    />
                    <p>{post.excerpt}</p>
                  </Link>
                </Grid>
              )
            })}
          </Grid>

          {allPosts.length > 0 && (
            <Typography variant="h2" gutterBottom>
              Other Articles
            </Typography>
          )}

          {allPosts.map(({ node: post }) => {
            return (
              <Link
                to={post.frontmatter.path}
                className="other-articles blog-link"
                key={post.id}
              >
                <Grid container spacing={32}>
                  <Grid item xs={12} sm={9}>
                    <Typography variant="overline">
                      {post.frontmatter.date}
                    </Typography>
                    <Typography variant="h3">
                      {post.frontmatter.title}
                    </Typography>
                    <Typography varint="p">{post.excerpt}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Img
                      sizes={
                        post.frontmatter.featuredImage.childImageSharp.sizes
                      }
                    />
                  </Grid>
                </Grid>
                <Divider />
              </Link>
            )
          })}
        </div>
        <Footer dark />
      </div>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 150)
          id
          frontmatter {
            title
            subtitle
            date(formatString: "MMMM DD, YYYY")
            path
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            topArticle
            featured
          }
        }
      }
    }
  }
`
