import React from 'react'
import { Link } from 'gatsby'
import { AppBar, Toolbar, Tab, Tabs } from '@material-ui/core'
import blogLogo from '../../images/blog-logo.svg'
// import Img from 'gatsby-image'
import './index.scss'

export default () => (
  <AppBar position="static" color="default">
    <Toolbar className="primary-toolbar">
      <Link to="/blog">
        <img className="blog-logo" src={blogLogo} alt="Upload my stock" />
      </Link>
    </Toolbar>
    <Toolbar>
      <Tabs
        value={0}
        // onChange={this.updateTab}
        indicatorColor="primary"
        textColor="primary"
        fullWidth
      >
        <Tab label="News and Stories" />
        {/* <Tab label="How To and Guides" />
              <Tab label="FAQ & Contact" /> */}
      </Tabs>
    </Toolbar>
  </AppBar>
)
